<ng-container *ngIf="src; else noPhoto">
    <img
        [src]="src"
        [alt]="alt"
        part="image"
    />
</ng-container>

<ng-template #noPhoto>
    <ng-container *ngIf="text || fio; else tplIcon">
        {{ text || fio }}
    </ng-container>
</ng-template>

<ng-template #tplIcon>
    <bazis-icon [icon]="emptyIcon"></bazis-icon>
</ng-template>

<ng-content></ng-content>
