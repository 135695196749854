import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[hrefProcessor]',
    standalone: true,
})
export class HrefProcessorDirective {
    @HostListener('click', ['$event'])
    public onClick(e) {
        if (!e.target.href) return;
        if (e.target.target === '_blank') return;
        if (e.target.host !== location.host && location.host.indexOf('localhost') === -1) return;

        e.preventDefault();
        this.router.navigateByUrl(e.target.pathname);
    }

    constructor(protected router: Router) {}
}
