<ng-container *transloco="let t">
    <div
        *ngIf="point"
        #tooltip
        class="bazis-coordinates"
        (click)="copy($event)"
    >
        <!-- широта, долгота -->
        <span>
            {{ point.coordinates[1] | localNumber : '1.0-6' }},&nbsp;
            {{ point.coordinates[0] | localNumber : '1.0-6' }}
        </span>
        <bazis-icon
            *ngIf="canCopy"
            slot="icon-only"
            name="duplicate"
            color="action"
        ></bazis-icon>

        <bazis-tooltip
            *ngIf="canCopy"
            [reference]="tooltip"
            placement="top"
            [isStatic]="true"
            [watchChange]="true"
            class="bh-popper-z-index"
        >
            {{ t(tooltipKey$ | async, tooltipParams) }}
        </bazis-tooltip>
    </div>
</ng-container>
