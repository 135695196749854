<ng-container *ngIf="alertService.alert$ | async as alert">
    <ng-container *ngIf="alert.options as alert">
        <div
            *transloco="let t"
            class="bazis-alert"
            [class]="alert.cssClass"
        >
            <div class="bazis-alert__wrapper">
                <div
                    *ngIf="alert.icon"
                    class="bazis-alert__icon"
                >
                    <bazis-sign-status [color]="alert.color">
                        <bazis-icon
                            slot="icon"
                            [name]="alert.icon"
                        ></bazis-icon>
                    </bazis-sign-status>
                </div>

                <div
                    class="bazis-alert__content"
                    part="content"
                >
                    <div
                        *ngIf="alert.headerKey"
                        class="bazis-alert__title"
                    >
                        {{ t(alert.headerKey, alert.headerParams) }}
                    </div>
                    <div
                        *ngIf="alert.header"
                        class="bazis-alert__title"
                    >
                        {{ alert.header }}
                    </div>
                    <div
                        class="bazis-alert__message"
                        *ngIf="alert.message"
                        [innerHTML]="alert.message"
                    ></div>
                    <div
                        class="bazis-alert__message"
                        *ngIf="alert.messageKey"
                        [innerHTML]="t(alert.messageKey, alert.messageParams)"
                    ></div>

                    <div class="bazis-alert__buttons">
                        <ng-container *ngFor="let button of alert.buttons">
                            <bazis-button
                                [fill]="button.fillButton || 'solid'"
                                [color]="button.color || 'action'"
                                [size]="button.size || 'default'" 
                                (click)="buttonClick(button)"
                            >
                                <bazis-icon
                                    *ngIf="button.iconStart"
                                    slot="start"
                                    [icon]="button.iconStart"
                                ></bazis-icon>
                                <bazis-icon
                                    *ngIf="button.iconEnd"
                                    slot="end"
                                    [icon]="button.iconEnd"
                                ></bazis-icon>
                                {{ t(button.titleKey, button.titleParams) }}
                            </bazis-button>
                        </ng-container>
                    </div>
                </div>

                <button
                    *ngIf="alert.hasCloseIcon"
                    class="bazis-alert__close"
                    (click)="dismiss()"
                >
                    <bazis-icon
                        [icon]="alert.closeIcon"
                        size="xs"
                    ></bazis-icon>
                </button>
            </div>
        </div>
        <div
            class="bazis-backdrop"
            (click)="dismiss()"
        ></div>
    </ng-container>
</ng-container>
