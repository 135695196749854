<!-- группы можно вынести в toast-group c учетом параметров,
принимающихся сервисом, можно добавлять/редактировать параметры при необходимости
-->
<ng-container *ngIf="toastService.toastGroups.$ | async as groups">
    <div
        *ngFor="let group of currentGroups$ | async"
        [class]="'bazis-toast-group bazis-toast-group_' + group"
        [part]="'group '+ group"
    >
        <div
            *ngFor="let toast of groups[group]"
            [ngClass]="['bazis-toast', toast.cssClass]"
            [part]="'toast ' + toast.type"
        >
            <div
                *ngIf="toast.icon"
                class="bazis-toast__icon"
                part="icon"
            >
                <bazis-sign-status [color]="toast.color">
                    <bazis-icon
                        slot="icon"
                        [name]="toast.icon"
                    ></bazis-icon>
                </bazis-sign-status>
            </div>
            <div
                class="bazis-toast__content"
                *transloco="let t"
            >
                <div
                    *ngIf="toast.title"
                    class="bazis-toast__title"
                    [part]="'title ' + toast.type"
                    [innerHTML]="toast.title"
                >
                </div>
                <div
                    *ngIf="toast.titleKey"
                    class="bazis-toast__title"
                    [part]="'title ' + toast.type"
                    [innerHTML]="t(toast.titleKey, toast.titleParams)"
                ></div>

                <div
                    class="bazis-toast__message"
                    part="message"
                    *ngIf="toast.message"
                    [innerHTML]="toast.message"
                >
                </div>
                <div
                    class="bazis-toast__message"
                    part="message"
                    *ngIf="toast.messageKey"
                    [innerHTML]="t(toast.messageKey, toast.messageParams)"
                ></div>
            </div>
            <button
                class="bazis-toast__close"
                [part]="'close ' + toast.type"
                (click)="dismiss(toast.id, group)"
            >
                <bazis-icon [icon]="toast.closeIcon"></bazis-icon>
            </button>
        </div>
    </div>
</ng-container>
