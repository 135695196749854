import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    Renderer2,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    HostBinding,
    ViewChild,
} from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, withLatestFrom } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

interface DatapickerViewConfig {
    typeHeadCalendar?: 'left' | 'center';
    typeSelectMonth?: 'list' | 'grid';
    iconLeft?: string;
    iconRight?: string;
    valueMonthWithComma?: boolean;
    typeValueMonth?: 'short' | 'long';
}

@Component({
    selector: 'bazis-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['datepicker.component.scss'],
    host: {
        '(mouseleave)': 'this._onMouseLeave()',
    },
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerElement implements OnInit {
    @HostBinding('style.--bazis-calendar-number') get styleVarNumber() {
        return `${this.calendarsNumber}`;
    }

    @Input() formatOutput: string;

    @Input() canToggleDay = true;

    @Input() minDate: string;

    @Input() maxDate: string;

    @Input() resetBtn: { use: boolean; txt?: string } = { use: false };

    @Input() currentDateBtn: { use: boolean; txt?: string } = { use: false };

    @Input() additionalBtns: { txt: string; clickFn: () => void }[];

    @Input() calendarsNumber = 1;

    @Input() value: Date[] = [null];

    @Input() startYear: number;

    @Input() marks$: Observable<{ [index: number]: string }>;

    @Input() disabledIntervals: string[][] = [];

    @Input() visibleAllYears: boolean = false;

    @Input() viewConfig: DatapickerViewConfig;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onMouseLeave: EventEmitter<Date[]> = new EventEmitter<Date[]>();

    @ViewChild('listYear') set listYear(el: ElementRef) {
        if (!el) return;

        this.listYearEl = el.nativeElement;
        this.yearSelected = this.listYearEl.querySelector('#yearSelected');
    }

    defaultViewConfig: DatapickerViewConfig = {
        typeHeadCalendar: 'left',
        typeSelectMonth: 'list',
        iconLeft: 'angle-small-left',
        iconRight: 'angle-small-right',
        valueMonthWithComma: false,
        typeValueMonth: 'long',
    };

    allViewConfig: DatapickerViewConfig;

    listYearEl;

    yearSelected;

    public monthListOpened: number = null;

    public yearsListOpened: number = null;

    currentYear$ = new BehaviorSubject(moment().year());

    currentMonth$ = new BehaviorSubject(moment().month());

    selectedDay$ = new BehaviorSubject(null);

    selectedMonth$ = new BehaviorSubject(null);

    selectedYear$ = new BehaviorSubject(null);

    diffMonth$ = new BehaviorSubject(null);

    diffYear$ = new BehaviorSubject(null);

    calendars$;

    updateMonth$ = this.diffMonth$.pipe(
        withLatestFrom(this.currentMonth$),
        tap(([diff, currentMonth]) => {
            if (!diff) return;
            let nextMonth = currentMonth + diff;
            if (nextMonth > 11) {
                nextMonth = 0;
                this.diffYear$.next(1);
            }
            if (nextMonth < 0) {
                nextMonth = 11;
                this.diffYear$.next(-1);
            }
            this.currentMonth$.next(nextMonth);
        }),
    );

    updateYear$ = this.diffYear$.pipe(
        withLatestFrom(this.currentYear$),
        tap(([diff, currentYear]) => {
            if (!diff) return;
            this.currentYear$.next(currentYear + diff);
        }),
    );

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    ngOnInit() {
        this.allViewConfig = {
            ...this.defaultViewConfig,
            ...this.viewConfig,
        };

        if (this.value[0] !== null) {
            this.currentYear$.next(moment(this.value[0]).year());
            this.currentMonth$.next(moment(this.value[0]).month());
        } else if (this.value[1] && this.value[1] !== null) {
            let month = moment(this.value[1]).month() - this.calendarsNumber;
            let year = moment(this.value[1]).year();
            if (month < 0) {
                year--;
                month += 12;
            }
            this.currentYear$.next(year);
            this.currentMonth$.next(month);
        } else if (this.minDate) {
            this.currentYear$.next(moment(this.minDate).year());
            this.currentMonth$.next(moment(this.minDate).month());
        } else if (this.maxDate && moment(this.maxDate).isBefore(moment())) {
            this.currentYear$.next(moment(this.maxDate).year());
            this.currentMonth$.next(moment(this.maxDate).month());
        } else {
            this.currentYear$.next(moment().year());
            this.currentMonth$.next(moment().month());
        }
        if (!this.marks$) {
            this.marks$ = of(null);
        }
        this.buildCalendar();
    }

    buildCalendar() {
        this.calendars$ = combineLatest([
            this.currentYear$,
            this.currentMonth$,
            this.selectedDay$,
            this.selectedMonth$,
            this.selectedYear$,
            this.marks$,
        ]).pipe(
            debounceTime(10),
            map(([currentYear, currentMonth, selectedDay, selectedMonth, selectedYear, marks]) => {
                const calendars = [];

                for (let i = 0; i < this.calendarsNumber; i++) {
                    let calendarMonth = (currentMonth + i) % 12;
                    let calendarYear = currentYear + Math.floor((currentMonth + i) / 12);

                    const monthList = this.monthNames.map((monthName, index) => {
                        return {
                            id: index,
                            name: monthName,
                            isCurrent: calendarMonth === index,
                            isMuted: this.isMutedMonth(calendarYear, index),
                        };
                    });
                    const yearList = this.getYearsList(calendarYear).map((year) => {
                        return {
                            id: year,
                            isCurrent: year === calendarYear,
                            isMuted: this.isMutedYear(calendarMonth, year),
                        };
                    });
                    const tmpDate = moment().month(calendarMonth).year(calendarYear);
                    const endDateOfMonth = tmpDate.endOf('month').date();
                    const daysMatrix = [1, 2, 3, 4, 5, 6].map((row, rowIndex) => {
                        return this.weekDaysNamesShort.map((col, colIndex) => {
                            const dayNumber = this.calculateShowingDay(
                                calendarYear,
                                calendarMonth,
                                rowIndex,
                                colIndex,
                            );

                            return {
                                dayNumber,
                                isValid: dayNumber > 0 && dayNumber <= endDateOfMonth,
                                isToday: this.isTodayDay(calendarYear, calendarMonth, dayNumber),
                                isSelected: this.isSelectedDay(
                                    calendarYear,
                                    calendarMonth,
                                    dayNumber,
                                ),
                                isMuted: this.isMutedDay(calendarYear, calendarMonth, dayNumber),
                                isInInterval: this.isDayInInterval(
                                    calendarYear,
                                    calendarMonth,
                                    dayNumber,
                                ),
                                isInIntervalLast: this.isDayInIntervalLast(
                                    calendarYear,
                                    calendarMonth,
                                    dayNumber,
                                ),
                                isInIntervalFirst: this.isDayInIntervalFirst(
                                    calendarYear,
                                    calendarMonth,
                                    dayNumber,
                                ),
                                mark: this.getDayMark(
                                    calendarYear,
                                    calendarMonth,
                                    dayNumber,
                                    marks,
                                ),
                                isWeekend: this.isWeekendDay(
                                    calendarYear,
                                    calendarMonth,
                                    dayNumber,
                                ),
                            };
                        });
                    });

                    calendars.push({
                        isFirst: i === 0,
                        isLast: i === this.calendarsNumber - 1,
                        calendarYear,
                        calendarMonth,
                        selectedDay,
                        selectedMonth,
                        selectedYear,
                        monthList,
                        yearList,
                        daysMatrix,
                        disabledPrevYear: this.isMutedYear(calendarMonth, calendarYear - 1),
                        disabledNextYear: this.isMutedYear(calendarMonth, calendarYear + 1),
                        //disabledPrevMonth: this.isMutedMonth(calendarYear, calendarMonth - 1),
                        //disabledNextMonth: this.isMutedMonth(calendarYear, calendarMonth + 1),
                        disabledPrevMonth: false, // есть моменты, когда стрелочки в две стороны дизейблены и весь месяц в дизейбле тоже и неясно, что делать. Поэтому стрелки пока будут всегда enable
                        disabledNextMonth: false,
                    });
                }
                return calendars;
            }),
        );
    }

    _onMouseLeave() {
        this.onMouseLeave.emit(this.value);
    }

    clear() {
        this.value = this.value.map((v) => null);
    }

    // clearOne(value) {
    //     this.value = this.value.filter((v) => v !== value);
    // }

    weekDaysNamesShort: Array<string> = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

    monthNames: Array<string> = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ];

    getYearsList(currentYear): number[] {
        const firstYear = this.getStartYear(currentYear);
        const years = [];
        if (this.visibleAllYears) {
            const endYear = this.maxDate
                ? moment(this.maxDate).year()
                : moment().add(20, 'y').year();
            for (let i = firstYear; i <= endYear; i++) years.push(i);
        } else {
            for (let i = firstYear; i < currentYear + 20; i++) years.push(i);
        }
        return years;
    }

    getStartDay(currentYear, currentMonth): number {
        const tmpDate = new Date(currentYear, currentMonth, 1);
        return -this.correctWeekDay(moment(tmpDate).day()) + 1;
    }

    getStartYear(currentYear): number {
        if (this.startYear) return this.startYear;

        return currentYear - 4;
    }

    isTodayDay(currentYear, currentMonth, day: number): boolean {
        return (
            currentYear == moment().year() &&
            currentMonth == moment().month() &&
            day == moment().date()
        );
    }

    isSelectedDay(currentYear, currentMonth, day: number): boolean {
        return (
            this.value.findIndex(
                (valueItem) =>
                    currentYear === moment(valueItem).year() &&
                    currentMonth === moment(valueItem).month() &&
                    day === moment(valueItem).date(),
            ) > -1
        );
    }

    isMutedDay(currentYear, currentMonth, day: number): boolean {
        const currentDate = new Date(currentYear, currentMonth, day);

        const basicMute =
            (this.minDate &&
                new Date(this.minDate) >= new Date(currentYear, currentMonth, day + 1)) ||
            (this.maxDate && new Date(this.maxDate) < currentDate);

        if (basicMute || !this.disabledIntervals || this.disabledIntervals.length === 0)
            return basicMute;
        return (
            this.disabledIntervals.findIndex(
                (interval) =>
                    currentDate >= new Date(interval[0] + 'T00:00:00.0000') &&
                    currentDate <= new Date(interval[1] + 'T00:00:00.0000'),
            ) > -1
        );
    }

    isDayInInterval(currentYear, currentMonth, day: number): boolean {
        if (this.value.length === 1 || !this.value[1] || !this.value[0]) return false;
        const tmpDate: Date = new Date(currentYear, currentMonth, day);
        return this.value[0] < tmpDate && this.value[1] > tmpDate;
    }

    isDayInIntervalLast(currentYear, currentMonth, day: number): boolean {
        if (this.value.length === 1 || !this.value[1] || !this.value[0]) return false;
        const tmpDate: Date = new Date(currentYear, currentMonth, day);
        return moment(this.value[1]).isSame(moment(tmpDate), 'date');
    }

    isDayInIntervalFirst(currentYear, currentMonth, day: number): boolean {
        if (this.value.length === 1 || !this.value[1] || !this.value[0]) return false;
        const tmpDate: Date = new Date(currentYear, currentMonth, day);
        return moment(this.value[0]).isSame(moment(tmpDate), 'date');
    }

    isWeekendDay(currentYear, currentMonth, day: number): boolean {
        const currentDate = new Date(currentYear, currentMonth, day);
        return moment(currentDate).day() === 6 || moment(currentDate).day() === 0;
    }

    getDayMark(currentYear, currentMonth, day: number, marks): string {
        if (!marks) return null;
        const tmpDate: Date = new Date(currentYear, currentMonth, day);
        return marks[tmpDate.getTime()];
    }

    isMutedMonth(currentYear, month: number) {
        if (!this.minDate && !this.maxDate) return false;
        const currentDate: Date = new Date(currentYear, month);
        if (this.minDate) {
            let minDate = new Date(this.minDate);
            minDate = new Date(moment(minDate).year(), moment(minDate).month());
            if (minDate > currentDate) return true;
        }
        if (this.maxDate) {
            let maxDate = new Date(this.maxDate);
            maxDate = new Date(moment(maxDate).year(), moment(maxDate).month());
            if (maxDate < currentDate) return true;
        }

        return false;
    }

    isMutedYear(currentMonth, year: number) {
        const currentDate: Date = new Date(year, currentMonth);
        return (
            (this.minDate && moment(this.minDate).year() > moment(currentDate).year()) ||
            (this.maxDate && moment(this.maxDate).year() < moment(currentDate).year())
        );
    }

    calculateShowingDay(currentYear, currentMonth, row: number, col: number): number {
        return row * 7 + col + this.getStartDay(currentYear, currentMonth);
    }

    validatingMinMaxDate(dates: Date[]): Date[] {
        const minDate: Date = new Date(this.minDate);
        const maxDate: Date = new Date(this.maxDate);
        if (dates[0] && this.minDate && moment(dates[0]).isBefore(moment(this.minDate), 'days')) {
            dates[0] = minDate;
        }
        if (
            this.maxDate &&
            dates[dates.length - 1] &&
            moment(dates[dates.length - 1]).isAfter(moment(this.maxDate), 'days')
        ) {
            dates[dates.length - 1] = maxDate;
        }
        return dates;
    }

    correctWeekDay(weekDay: number): number {
        if (weekDay == 0) weekDay = 7; // поправим воскресенье
        return weekDay - 1;
    }

    selectTodayDay() {
        this.value[0] = new Date();
        this.currentMonth$.next(moment(this.value[0]).month());
        this.currentYear$.next(moment(this.value[0]).year()); // getUTCFullYear
        if (this.formatOutput) {
            this.valueChange.emit(this.value.map((v) => moment(v).format(this.formatOutput)));
        } else {
            this.valueChange.emit(this.value);
        }
    }

    handleDayClick(day, currentYear, currentMonth) {
        if (day.isSelected) {
            if (!this.canToggleDay) return;
            else {
                this.value = [];
                this.changeDate();
                return;
            }
        }
        this.changeDay(day.dayNumber, currentYear, currentMonth);
    }

    changeDate() {
        this.value = this.validatingMinMaxDate(this.value);

        if (this.formatOutput) {
            this.valueChange.emit(this.value.map((v) => moment(v).format(this.formatOutput)));
        } else {
            this.valueChange.emit(this.value);
        }

        this.selectedDay$.next(moment(this.value[0]).date().toString());
        this.selectedMonth$.next(this.monthNames[moment(this.value[0]).month()]);
        this.selectedYear$.next(moment(this.value[0]).year().toString());
    }

    changeDay(selectedDay: number, currentYear, currentMonth) {
        const newValue = moment().set({
            year: currentYear,
            month: currentMonth,
            date: selectedDay,
            hour: 0,
            minute: 0,
        });
        const newValueDate = newValue.toDate();
        if (this.value.length === 1) {
            this.value = [newValueDate];
        } else {
            if (
                (this.value[1] &&
                    this.value[0] &&
                    this.value[1].valueOf() !== this.value[0].valueOf()) ||
                (!this.value[1] && !this.value[0])
            ) {
                this.value = [newValueDate, newValueDate];
            } else if (!this.value[1] || this.value[1].valueOf() === this.value[0].valueOf()) {
                if (moment(this.value[0]).isAfter(newValue)) {
                    this.value[1] = this.value[0];
                    this.value[0] = newValueDate;
                } else {
                    this.value[1] = newValueDate;
                }
            } else {
                if (moment(this.value[1]).isBefore(newValue)) {
                    this.value[0] = this.value[1];
                    this.value[1] = newValueDate;
                } else {
                    this.value[0] = newValueDate;
                }
            }
        }

        this.changeDate();
    }

    changeCurrentMonth(month) {
        if (!month.isMuted) this.currentMonth$.next(month.id);
    }

    changeCurrentYear(year: number) {
        this.currentYear$.next(year);
        this.yearSelected = this.listYearEl.querySelector('#yearSelected');
    }

    stepNextMonth() {
        this.diffMonth$.next(1);
    }

    stepPrevMonth() {
        this.diffMonth$.next(-1);
    }

    stepNextYear() {
        this.diffYear$.next(1);
    }

    stepPrevYear() {
        this.diffYear$.next(-1);
    }

    public toogleMonthList(calendarIndex) {
        this.monthListOpened = this.monthListOpened === calendarIndex ? null : calendarIndex;
        this.yearsListOpened = null;
    }

    public toogleYearsList(calendarIndex) {
        if (this.yearsListOpened === calendarIndex) {
            this.yearsListOpened = null;
        } else {
            this.yearsListOpened = calendarIndex;

            setTimeout(() => {
                if (this.yearSelected) this.yearSelected.scrollIntoView();
            }, 0);
        }
        this.monthListOpened = null;
    }

    public closeLists() {
        this.yearsListOpened = null;
        this.monthListOpened = null;
    }
}
