import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Alert, AlertOptions } from '@bazis/shared/models/alert.types';

@Injectable({
    providedIn: 'root',
})
export class BazisAlertService {
    private _defaultParams: AlertOptions = {
        messageKey: '',
        headerKey: '',
        buttons: [
            {
                titleKey: 'ok',
            },
        ],
        hasCloseIcon: true,
        closeIcon: 'cross-small',
    };

    private _alert: Alert;

    private _needUpdateAlerts$ = new BehaviorSubject(true);

    alert$ = this._needUpdateAlerts$.asObservable().pipe(map(() => this._alert));

    constructor() {}

    create(params) {
        params = {
            ...this._defaultParams,
            ...params,
        };

        const alert: Alert = {
            options: params,
            onDidDismiss: () => {
                return new Promise((resolve) => {
                    alert.dismiss = resolve;
                });
            },
        };

        this._alert = alert;

        this._needUpdateAlerts$.next(true);
        return alert;
    }

    dismiss(result = null, alert = this._alert) {
        if (!alert) return;

        alert.dismiss(result);
        this._alert = null;
        this._needUpdateAlerts$.next(true);
    }
}
