import { ViewEncapsulation, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'bazis-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['avatar.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class AvatarElement implements OnInit {
    @Input() src: string;

    @Input() alt: string;

    @Input() lastName = '';

    @Input() firstName = '';

    @Input() emptyIcon = 'user';

    @Input() size: 'xs' | 'small' | 'medium' | 'default' | 'large' = 'default';

    @Input() active: boolean;

    @Input() disabled: boolean;

    @Input() text: string;

    @HostBinding('role') get roleElement() {
        return 'img';
    }

    fio;

    @HostBinding('class') get avatarClass() {
        return {
            'bazis-avatar': true,
            [`bazis-avatar_${this.size}`]: !!this.size,
            'bazis-avatar--active': this.active,
            'bazis-avatar--disabled': this.disabled,
            'bazis-avatar_photo': this.src,
        };
    }

    constructor() {}

    ngOnInit(): void {
        this.disabled = this.disabled !== undefined && this.disabled !== false;
        this.active = this.active !== undefined && this.active !== false;

        if (this.firstName || this.lastName)
            this.fio = this.lastName.charAt(0) + this.firstName.charAt(0);
    }
}
