import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { BazisLocaleService } from '@bazis/shared/services/locale.service';

@Pipe({
    name: 'localNumber',
    pure: false,
    standalone: true,
})
export class LocalNumberPipe implements PipeTransform {
    constructor(private localeService: BazisLocaleService) {}

    transform(
        value: any,
        format: string,
        roundDirection: 'up' | 'down' | 'none' = 'none',
        locale = this.localeService.locale,
    ) {
        const valueToFormat =
            roundDirection === 'none'
                ? value
                : this.getNumberWithFixedDigits(value, format, roundDirection);
        return formatNumber(valueToFormat, locale, format);
    }

    protected getNumberWithFixedDigits(value, format, roundDirection: 'up' | 'down') {
        const formatData = format.split('-');
        const digits = formatData.length > 1 ? +formatData[1] : 0;

        const roundFn = roundDirection === 'down' ? Math.floor : Math.ceil;
        return roundFn(value * 10 ** digits) / 10 ** digits;
    }
}
