<div
    *transloco="let t"
    class="bazis-empty"
    [ngClass]="cssClass"
>
    <img
        *ngIf="pathImg"
        [src]="pathImg"
        alt="empty content decor"
    />
    <h5 *ngIf="titleKey">
        {{ t(titleKey, titleParams) }}
    </h5>
    <p
        *ngIf="messageKey"
        [innerHTML]="t(messageKey, messageParams)"
    ></p>
    <bazis-button
        *ngIf="buttonOptions"
        [fill]="buttonOptions.fill || 'solid'"
        [color]="buttonOptions.color"
        [size]="buttonOptions.size"
        (click)="addAction($event)"
    >
        <bazis-icon
            *ngIf="buttonOptions.iconStart"
            [icon]="buttonOptions.iconStart"
            slot="start"
        ></bazis-icon>
        <bazis-icon
            *ngIf="buttonOptions.iconEnd"
            [icon]="buttonOptions.iconEnd"
            slot="end"
        ></bazis-icon>
        {{ t(buttonOptions.titleKey) }}
    </bazis-button>
    <bazis-link
        *ngIf="linkOptions"
        [href]="linkOptions.href"
        [target]="linkOptions.href"
        [size]="linkOptions.size"
        [borderStyle]="linkOptions.borderStyle"
    >
        <bazis-icon
            *ngIf="linkOptions.iconStart"
            [icon]="linkOptions.iconStart"
            slot="start"
        ></bazis-icon>
        <bazis-icon
            *ngIf="linkOptions.iconEnd"
            [icon]="linkOptions.iconEnd"
            slot="end"
        ></bazis-icon>
        {{ t(linkOptions.titleKey) }}
    </bazis-link>
</div>
