<ng-container *transloco="let t">
    <div
        class="bazis-dropdown bazis-dropdown_action"
        [class.bazis-dropdown_top]="direction === 'top'"
        clickOutside
        (clickOutside)="hideDropdown()"
        [excludeElement]="trigger"
    >
        <bazis-button
            *ngIf="typeTrigger === 'button'"
            #trigger
            class="bazis-dropdown__trigger"
            [size]="sizeTrigger || 'default'"
            [fill]="fillTrigger || 'solid'"
            [color]="colorTrigger"
            [active]="open"
            (click)="toggleDropdown($event)"
        >
            <bazis-icon
                *ngIf="iconStart"
                slot="start"
                [icon]="iconStart"
                [size]="iconSize"
            ></bazis-icon>
            {{ t(titleKey, titleParams) }}
            <bazis-icon
                *ngIf="iconEnd"
                [slot]="titleKey ? 'end' : 'icon-only'"
                [icon]="iconEnd"
                [size]="iconSize"
            ></bazis-icon>
        </bazis-button>

        <bazis-link
            *ngIf="typeTrigger === 'link'"
            #trigger
            class="bazis-dropdown__trigger bazis-dropdown__trigger_link"
            borderStyle="none"
            [size]="sizeTrigger || ''"
            (click)="toggleDropdown($event)"
        >
            <bazis-icon
                *ngIf="iconStart"
                slot="start"
                [icon]="iconStart"
                [size]="iconSize"
            ></bazis-icon>
            {{ t(titleKey, titleParams) }}
            <bazis-icon
                *ngIf="iconEnd"
                [slot]="titleKey ? 'end' : 'icon-only'"
                [icon]="iconEnd"
                [size]="iconSize"
            ></bazis-icon>
        </bazis-link>

        <div
            *ngIf="open"
            class="bazis-dropdown__body"
        >
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>
