import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import isEqual from 'lodash.isequal';
import { Toast } from '@bazis/shared/models/toast.types';

export const TOAST_GROUPS = ['top', 'bottom', 'center', 'middle'];

@Injectable({
    providedIn: 'root',
})
export class BazisToastService {
    protected _defaultParams: Toast = {
        title: '',
        message: '',
        titleKey: '',
        messageKey: '',
        cssClass: '',
        duration: 5000,
        buttons: [],
        position: 'bottom',
        animated: false,
        icon: '',
        color: '',
        id: '',
        createdAt: 0,
        stack: false,
        closeIcon: 'cross-small',
    };

    toastGroups = new TemplateObservable({
        top: [],
        bottom: [],
        center: [],
        middle: [],
    });

    protected _typeProperties = {
        success: {
            icon: 'check',
            color: 'success',
            position: 'top',
        },
        error: {
            icon: 'cross',
            color: 'danger',
            position: 'top',
            stack: true,
        },
        warning: {
            icon: 'exclamation',
            color: 'warning',
            position: 'top',
        },
    };

    constructor() {
        this.startCounter();
    }

    protected startCounter() {
        setInterval(() => {
            const currentTime = new Date().getTime();
            const toastGroups = this.toastGroups._;
            TOAST_GROUPS.forEach((group) => {
                toastGroups[group] = toastGroups[group].filter((v) => v.dismissAt > currentTime);
            });
            this.toastGroups.set(toastGroups);
        }, 1000);
    }

    create(params: Partial<Toast>): Toast {
        if (params.type && this._typeProperties[params.type]) {
            params = {
                ...params,
                ...this._typeProperties[params.type],
            } as Partial<Toast>;
        }

        params = {
            ...this._defaultParams,
            ...params,
            id: params.id || uuidv4(),
            createdAt: new Date().getTime(),
        };
        params.dismissAt = params.createdAt + params.duration;
        this.toastGroups.set(this.stackToasts(params));
        return params;
    }

    protected stackToasts(params) {
        const toastGroups = this.toastGroups._;
        const targetGroup = toastGroups[params.position];
        let addToGroup = !params.stack || targetGroup.length === 0;
        if (!addToGroup) {
            const lastElement = targetGroup[targetGroup.length - 1];
            if (!lastElement.stack) {
                addToGroup = true;
            } else {
                const copy = { ...lastElement };
                copy.createdAt = params.createdAt;
                copy.dismissAt = params.dismissAt;
                copy.id = params.id;
                addToGroup = !isEqual(copy, params);
            }
        }
        if (addToGroup) {
            targetGroup.push(params);
        } else {
            targetGroup[targetGroup.length - 1].createdAt = params.createdAt;
            targetGroup[targetGroup.length - 1].dismissAt = params.dismissAt;
        }
        return toastGroups;
    }

    dismiss(id, group = null) {
        const toastGroups = this.toastGroups._;
        if (group) {
            toastGroups[group] = toastGroups[group].filter((v) => v.id !== id);
        } else {
            TOAST_GROUPS.forEach((toastGroup: string) => {
                toastGroups[toastGroup] = toastGroups[toastGroup].filter((v) => v.id !== id);
            });
        }
        this.toastGroups.set(toastGroups);
    }

    // TODO: dismiss all toast
    dismissAllToast(position = null) {
        if (position) {
        } else {
        }
    }
}
