<div
    *ngIf="segmentList?.length"
    class="bazis-segment__content"
    [ngClass]="{
        'bazis-segment__content_equal': isEqualParts,
        'bazis-segment__content_scrollable': scrollable
    }"
>
    <ng-container *ngFor="let segment of segmentList">
        <ng-container *ngIf="segment.url; else tplNoUrl">
            <!-- если сегменты ссылочные, то активность сегмента = активности ссылки -->
            <div
                *ngIf="!hideByZeroCount || segment.isExactLink || segment.titleParams?.count > 0"
                class="bazis-segment__item"
                [ngClass]="{
                    hidden: segment.hidden,
                    'bazis-segment__item--disabled': segment.disabled
                }"
                [routerLink]="segment.url"
                routerLinkActive="bazis-segment__item--selected"
                [routerLinkActiveOptions]="{ exact: segment.isExactLink }"
                (click)="selectSegment(segment.id)"
            >
                <button
                    part="native"
                    class="bazis-btn-native"
                    [disabled]="segment.disabled"
                >
                    <ng-container
                        [ngTemplateOutlet]="segmentContent"
                        [ngTemplateOutletContext]="{
                            segment: segment
                        }"
                    ></ng-container>
                </button>
                <div
                    part="indicator"
                    class="bazis-segment__indicator"
                >
                    <div
                        part="indicator-background"
                        class="bazis-segment__indicator__bg"
                    >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #tplNoUrl>
            <!-- если сегмент это не ссылка, то активируем сегмент с текущим значением -->
            <div
                *ngIf="
                    !hideByZeroCount ||
                    segment.id === selectedSegment ||
                    segment.titleParams?.count > 0
                "
                class="bazis-segment__item"
                [ngClass]="{
                    'bazis-segment__item--selected': segment.id === selectedSegment,
                    hidden: segment.hidden,
                    'bazis-segment__item--disabled': segment.disabled
                }"
                (click)="selectSegment(segment.id)"
            >
                <button
                    part="native"
                    class="bazis-btn-native"
                    [disabled]="segment.disabled"
                >
                    <ng-container
                        [ngTemplateOutlet]="segmentContent"
                        [ngTemplateOutletContext]="{
                            segment: segment
                        }"
                    ></ng-container>
                </button>
                <div
                    part="indicator"
                    class="bazis-segment__indicator"
                >
                    <div
                        part="indicator-background"
                        class="bazis-segment__indicator__bg"
                    >
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>

<ng-template
    #segmentContent
    let-segment="segment"
>
    <span class="bazis-btn__inner">
        <bazis-icon
            *ngIf="segment.icon"
            [icon]="segment.icon"
            part="icon"
        ></bazis-icon>
        <span *ngIf="segment.title">
            {{ segment.title }}
        </span>
        <span *ngIf="segment.titleKey && !segment.type">
            {{ segment.titleKey | transloco : segment.titleParams }}
        </span>
        <span *ngIf="segment.type && segmentTemplate">
            <ng-container
                [ngTemplateOutlet]="segmentTemplate"
                [ngTemplateOutletContext]="{ segment: segment }"
            ></ng-container>
        </span>
    </span>
</ng-template>
