import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { createCustomElement } from '@angular/elements';
import { AvatarElement } from './avatar.component';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { BadgeModule } from '../badge/badge.module';

@NgModule({
    declarations: [AvatarElement],
    imports: [CommonModule, IconModule, BadgeModule],
    exports: [AvatarElement],
})
export class AvatarModule implements DoBootstrap {
    constructor(private injector: Injector) {}

    ngDoBootstrap(): void {
        const el = createCustomElement(AvatarElement, {
            injector: this.injector,
        });

        customElements.define('bazis-avatar', el);
    }
}
