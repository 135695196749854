import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'bazis-browse-default',
    templateUrl: './browse-default.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisBrowseDefaultComponent {
    @HostBinding('class.bazis-browse-default') get getHostClass() {
        return true;
    }

    @Input()
    titleKey;

    @Input()
    titleParams;

    @Input()
    value;

    @Input()
    isEditable = false;

    @Input()
    unitKey;

    @Output()
    edit = new EventEmitter();

    icons;

    constructor(private configurationService: BazisConfigurationService) {
        this.icons = this.configurationService.controlIcons;
    }
}
