import { ViewEncapsulation, Component, Input } from '@angular/core';

@Component({
    selector: 'bazis-interrogation',
    templateUrl: './interrogation.component.html',
    styleUrls: ['interrogation.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class InterrogationElement {
    @Input() icon: string = 'interrogation';

    @Input() iconSize: string = 'xxs';

    @Input() tooltipText: string = '';

    // действие которое вызывает тултип, по умолчанию наведение
    @Input() tooltipEventType: 'hover' | 'click' = 'hover';

    // расположение тултипа, по умолчанию снизу от начала вызывающего элемента.
    @Input() tooltipPlacement:
        | 'auto'
        | 'auto-start'
        | 'auto-end'
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end' = 'bottom-start';

    // смещение тултипа [x, y]
    @Input() tooltipOffset: [number, number] = [-10, 6];

    @Input() tooltipIsStatic: boolean = true;

    @Input() type: 'dark' | 'light' = 'dark';
}
