import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    ViewChild,
} from '@angular/core';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ClickOutsideDirective } from '@bazis/shared/directives/click-outside.directive';
import { LinkModule } from '../web/link/link.module';

@Component({
    selector: 'bazis-dropdown-actions',
    templateUrl: './dropdown-actions.component.html',
    standalone: true,
    imports: [
        IconModule,
        ButtonModule,
        IconModule,
        ColorDirective,
        CommonModule,
        TranslocoModule,
        ClickOutsideDirective,
        LinkModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisDropdownActionsComponent {
    @HostListener('click', ['$event.target'])
    public onClick(targetElement) {
        if (this.triggerEl && targetElement !== this.triggerEl) {
            this.hideDropdown();
        }
    }

    @ViewChild('trigger') set trigger(el: ElementRef) {
        if (!el) return;

        this.triggerEl = el.nativeElement;
    }

    // ключ заголовка триггера, не обязательный
    @Input() titleKey: string;

    // параметры заголовка триггера, не обязательный
    @Input() titleParams: string;

    // размер кнопки триггера, не обязательный, будет использован дефолтный
    @Input() sizeTrigger: string;

    // заполненность кнопки триггера, не обязательный, будет использован fill=solid
    @Input() fillTrigger: string;

    // цвет кнопки триггера, применение директивы color, не обязательный, не будет примененена
    @Input() colorTrigger: string;

    // иконка кнопки триггера, не обязательный, если есть заголовок
    @Input() iconEnd = 'menu-dots';

    // иконка кнопки триггера (slot=start), не обязательный
    @Input() iconStart;

    // размер иконки триггера, не обязательный
    @Input() iconSize = 's';

    // направление выпадения
    @Input() direction: 'bottom' | 'top' = 'bottom';

    @Input() typeTrigger: 'button' | 'link' = 'button';

    open = false;

    triggerEl;

    toggleDropdown(e) {
        this.open = !this.open;
    }

    hideDropdown() {
        this.open = false;
    }
}
