import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ButtonModule } from '@bazis/shared/components/web/button/button.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule, NgClass } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { LinkModule } from '../web/link/link.module';

interface IButtonOptions {
    titleKey: string;
    fill?: string;
    color?: string;
    size?: string;
    iconStart?: string;
    iconEnd?: string;
}

interface ILinkOptions {
    titleKey: string;
    href: string;
    size?: string;
    iconStart?: string;
    iconEnd?: string;
    borderStyle?: 'dotted' | 'dashed' | 'solid' | 'none';
    target?: '_blank' | '_self' | '_parent' | '_top';
}

@Component({
    selector: 'bazis-empty',
    templateUrl: './empty.component.html',
    standalone: true,
    imports: [
        IconModule,
        ButtonModule,
        ColorDirective,
        NgClass,
        TranslocoModule,
        CommonModule,
        LinkModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent implements OnInit {
    @Input() titleKey: string = null;

    @Input() titleParams: any;

    @Input() messageKey: string = null;

    @Input() messageParams: any;

    @Input() cssClass: string = '';

    @Input() pathImg: string = '';

    @Input() buttonOptions: IButtonOptions;

    @Input() linkOptions: ILinkOptions;

    @Output()
    buttonClick = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    addAction(event) {
        this.buttonClick.emit(event);
    }
}
