import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'bazis-thumbnail',
    template: `<img
            *ngIf="isLoaded"
            [src]="src"
            [alt]="alt"
            (error)="isLoaded = false"
        />
        <span
            *ngIf="!isLoaded"
            part="icon"
        >
            <bazis-icon
                color="action"
                [icon]="iconNoImg"
                [size]="iconSize"
            ></bazis-icon
        ></span>`,
    styleUrls: ['thumbnail.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ThumbnailElement {
    @Input() src: string;

    @Input() alt: string;

    @Input() width: number;

    @Input() height: number;

    @Input() radius: number;

    @Input() iconNoImg: string = 'picture';

    @Input() iconSize: 'xxs' | 'xs' | 's' | 'md' | 'l' | 'xl' = 'l';

    @HostBinding('role') get roleElement() {
        return 'img';
    }

    @HostBinding('style') get thumbnailStyle() {
        return {
            [`--width`]: this.width + 'px',
            [`--height`]: this.height + 'px',
            [`--border-radius`]: this.radius ? this.radius + 'px' : '',
        };
    }

    isLoaded = true;

    constructor() {}
}
