<div
    [ngClass]="[
        'bazis-loader_' + theme,
        type === 'inline' ? 'bazis-loader_inline' : 'bazis-loader',
        cssClass
    ]"
>
    <div class="bazis-loader__content">
        <div
            *ngIf="loader === 'dots'"
            class="bazis-loader__dots"
        >
            <div *ngFor="let item of [].constructor(amountDots)" class="bazis-loader__dot"></div>
        </div>
        <svg
            *ngIf="loader === 'spinner'"
            class="bazis-loader__spinner"
        >
            <circle
                [attr.cx]="centerX"
                [attr.cy]="centerY"
                [attr.r]="radius"
                fill="none"
                stroke-width="1"
            ></circle>
        </svg>
        <p
            *ngIf="message"
            [innerHTML]="message"
        ></p>
    </div>
</div>
