import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';

interface SegmentItem {
    id: string | number;
    title?: string;
    titleKey?: string;
    titleParams?: any;
    type?: string; // entityType
    disabled?: boolean;
    url?: string;
    isExactLink?: boolean;
    // name иконки или src
    icon?: string;
    // iconPosition?: 'start' | 'end';
    hidden?: boolean;
}

@Component({
    selector: 'bazis-segment',
    templateUrl: './segment.component.html',
    styleUrls: ['segment.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class SegmentElement {
    @HostBinding('class') get segmentStyle() {
        return {
            [`bazis-segment_${this.size}`]: !!this.size,
        };
    }

    @Input()
    segmentList: SegmentItem[];

    @Input()
    selectedSegment: string | number;

    @Input()
    hideByZeroCount = false;

    @Input() segmentTemplate: TemplateRef<any>;

    @Input() isEqualParts: boolean = false;

    @Input() scrollable: boolean = false;

    @Input() size: 'large' | 'default' | 'small' = 'default';

    @Output()
    selectedSegmentChange = new EventEmitter();

    selectSegment(segment: string | number) {
        this.selectedSegment = segment;
        this.selectedSegmentChange.emit(this.selectedSegment);

        // if (!this.needScroll) return;
        // setTimeout необходим, чтобы не приходилось кликать дважды на кнопку, если передано Output значение
        // данный баг двойного клика наблюдается в FF MAC OS
        // setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]), 0);
    }
}
