<ng-container *transloco="let t">
    <div
        *ngIf="titleKey"
        class="bazis-browse-default__title"
    >
        {{ t(titleKey, titleParams) }}
    </div>
    <div
        *ngIf="value !== undefined; else tplNoValue"
        class="bh-flex bh-align-items-center"
    >
        {{ value }}
        <ng-container *ngIf="unitKey">{{ t(unitKey) }}</ng-container>
        <ng-container *ngIf="isEditable">
            <bazis-button
                fill="clear"
                color="action"
                size="xs"
                class="bh-margin-start-2x"
                (click)="edit.emit()"
            >
                <bazis-icon
                    [name]="icons.edit"
                    slot="icon-only"
                ></bazis-icon>
            </bazis-button>
        </ng-container>
    </div>
    <ng-template #tplNoValue>
        <div class="bh-flex bh-align-items-center">
            <ng-content></ng-content>
        </div>
    </ng-template>
</ng-container>
