import { Injectable, Optional } from '@angular/core';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import {
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    finalize,
    mergeMap,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
    zip,
} from 'rxjs';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { catchError, debounceTime, filter, map, skip, take, tap } from 'rxjs/operators';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { EntData, EntList, GroupRequestItem } from '@bazis/shared/models/srv.types';
import { buildFilterStr } from '@bazis/utils';
import moment from 'moment';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { v4 as uuidv4 } from 'uuid';
import { FILE_EXTENSIONS } from '@bazis/form/models/form-element.types';
import { API_DATETIME_FORMAT, SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { BazisToastService } from '@bazis/shared/services/toast.service';

@Injectable({
    providedIn: 'root',
})
export class BazisChatService {
    generalSettings = {
        participantsSettings: null,
        addParticipantSettings: null,
        chatTitle$: null,
    };

    protected unreadLoading: TemplateObservable<boolean> = new TemplateObservable(false);

    unreadLoading$ = this.unreadLoading.$;

    protected unread = new TemplateObservable([]);

    protected notSentMessages = [];

    imgExtensions = ['jpg', 'png', 'gif', 'webp', 'jpeg'];

    urlRegex = /(https?:\/\/[^\s]*)/g;

    unread$ = this.unread.$.pipe(
        switchMap((unreadIds) =>
            unreadIds.length > 0
                ? combineLatest(
                      unreadIds.map((id) => this.entityService.getEntity$('chat.chat', id)),
                  )
                : of([]),
        ),
        map((chats) => {
            const notRead = chats.filter((chat) => chat.$snapshot.not_read_messages_count > 0);
            notRead.sort(this.sortChatsList);
            return notRead;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected chatListLoading: TemplateObservable<{ type: string; search: string }> =
        new TemplateObservable(null);

    chatListLoading$ = this.chatListLoading.$;

    protected chatList: TemplateObservable<string[]> = new TemplateObservable([]);

    chatList$ = this.chatList.$.pipe(
        switchMap((unreadIds) =>
            unreadIds.length > 0
                ? combineLatest(
                      unreadIds.map((id) => this.entityService.getEntity$('chat.chat', id)),
                  )
                : of([]),
        ),
        map((v) => {
            v.sort(this.sortChatsList);
            return v;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected chatMessages: {
        chatId: string;
        loading: TemplateObservable<string[]>;
        pinnedMessages: TemplateObservable<{ id: string; dt_created: number }[]>;
        messages: TemplateObservable<{ id: string; dt_created: number }[]>;
    };

    private _reset$ = combineLatest([this.authService.userId$, this.authService.role$]).pipe(
        map(([userId, role]) => `${userId}-${role}`),
        distinctUntilChanged(),
        skip(1),
        tap((v) => {
            this.clearChats();
        }),
    );

    isEndOfCurrentChat = new TemplateObservable(false);

    isStartOfCurrentChat = new TemplateObservable(false);

    sumUnread$ = this.unread$.pipe(
        map((unread) =>
            unread.reduce((acc, chat) => acc + chat.$snapshot.not_read_messages_count, 0),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    projectEmojis = [];

    projectReactions = [];

    reactions$ = this.entityService.getEntityList$('chat.emoji', { limit: 1000 }).pipe(
        map((v) => v.list),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    reactionChange$ = this.socketService.message$.pipe(
        filter(
            (message) =>
                message.$snapshot.title === 'chat.new_chat_message_reaction' ||
                message.$snapshot.title === 'chat.delete_chat_message_reaction',
        ),
        mergeMap((message) =>
            this.entityService.getEntity(
                'chat.chat_message',
                message.$snapshot.extra_data.message_id,
            )
                ? this.entityService.getEntity$(
                      'chat.chat_message',
                      message.$snapshot.extra_data.message_id,
                      { forceLoad: true },
                  )
                : of(null),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    newMessage$ = this.socketService.message$.pipe(
        filter((message) => message.$snapshot.title === 'chat.new_message_event'),
        debounceTime(150),
        mergeMap((message) =>
            combineLatest([
                this.entityService.getEntity$('chat.chat', message.$snapshot.extra_data.chat_id),
                this.entityService.getEntity$(
                    'chat.chat_message',
                    message.$snapshot.extra_data.message_id,
                ),
                of(this.entityService.getEntity('chat.chat', message.$snapshot.extra_data.chat_id)),
            ]).pipe(
                filter(([chatEntity, messageEntity, oldChat]) => !!chatEntity && !!messageEntity),
                catchError((e) => EMPTY),
                take(1),
            ),
        ),
        withLatestFrom(this.authService.userId$),
        debounceTime(0),
        tap(([[chat, message, oldChat], userId]) => {
            const isReadByCurrentUser =
                oldChat && userId
                    ? message.$snapshot.read_by.findIndex((v) => v.id === userId) > -1
                    : true;
            this.modifyChatItem(chat.id, isReadByCurrentUser ? 0 : 1, message, !oldChat);
            this.addMessageToChatList(chat.id, message);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    editMessage$ = this.socketService.message$.pipe(
        filter((message) => message.$snapshot.title === 'chat.update_message_event'),
        mergeMap((message) =>
            this.entityService
                .getEntity$('chat.chat_message', message.$snapshot.extra_data.message_id, {
                    forceLoad: true,
                })
                .pipe(take(1)),
        ),
        tap((message) => {
            const chatEntity = this.entityService.getEntity('chat.chat', message.$snapshot.chat.id);
            if (
                chatEntity &&
                chatEntity.$snapshot.last_message &&
                chatEntity.$snapshot.last_message[0]?.id === message.id
            ) {
                this.modifyChatItem(message.$snapshot.chat.id, 0, message);
            }
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    deleteMessage$ = this.socketService.message$.pipe(
        filter((message) => message.$snapshot.title === 'chat.delete_message_event'),
        switchMap((message) =>
            this.updateChatListItemAfterMessageRemove$(
                message.$snapshot.extra_data.message_id,
                message.$snapshot.extra_data.chat_id,
            ),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    readMessage$ = this.socketService.message$.pipe(
        filter((message) => message.$snapshot.title === 'chat.message_readed'),
        withLatestFrom(this.authService.userId$),
        tap(([message, userId]) =>
            this.markOlderMessagesAsRead(
                message.$snapshot.extra_data.chat_id,
                message.$snapshot.extra_data.message_id,
                message.$snapshot.extra_data.readed_by,
                userId,
            ),
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        protected entityService: BazisEntityService,
        protected srvService: BazisSrvService,
        protected authService: BazisAuthService,
        protected socketService: BazisWebSocketService,
        protected toastService: BazisToastService,
    ) {
        this._reset$.subscribe();
        this.newMessage$.subscribe();
        this.editMessage$.subscribe();
        this.reactionChange$.subscribe();
        this.deleteMessage$.subscribe();
        this.readMessage$.subscribe();
    }

    initCurrentChatMessages(chatId) {
        this.isEndOfCurrentChat.set(false);
        this.isStartOfCurrentChat.set(false);
        this.notSentMessages = [];
        this.nextMessages.set(null);
        this.chatMessages = {
            chatId,
            messages: new TemplateObservable([]),
            pinnedMessages: new TemplateObservable([]),
            loading: new TemplateObservable([]),
        };
    }

    getMessages$() {
        return this.chatMessages.messages.$;
    }

    getChatPinnedMessages$() {
        return this.chatMessages.pinnedMessages.$;
    }

    getChatMessagesLoader$() {
        return this.chatMessages.loading.$.pipe(
            map((loadings) => loadings.reduce((acc, current) => ({ ...acc, [current]: true }), {})),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    loadChats(type: 'newer' | 'older' | 'full' = 'full', search = '', entity = null) {
        if (this.chatListLoading._?.search === search && this.chatListLoading._?.type === type)
            return;

        this.chatListLoading.set({ type, search });
        const chatFilters: any = {};

        if (this.chatList._.length > 0 && entity) {
            if (type === 'newer') {
                chatFilters.dt_updated__gt = entity.$snapshot.dt_updated;
            } else if (type === 'older') {
                chatFilters.dt_updated__lt = entity.$snapshot.dt_updated;
            }
        }

        const params = {
            filter: buildFilterStr(chatFilters),
            sort: '-dt_updated',
        };

        this.entityService
            .getEntityList$('chat.chat', { search, params, limit: 1000, saveSeparateItems: true })
            .pipe(
                tap((r) => {
                    if (
                        this.chatListLoading._?.search !== search ||
                        this.chatListLoading._?.type !== type
                    )
                        return;
                    const ids = r.list.map((v) => v.id);
                    if (type === 'full') {
                        this.chatList.set(ids);
                        return;
                    }
                    this.addChatToChatList(ids);
                }),
                catchError((e) => {
                    return of(null);
                }),
                finalize(() => {
                    this.chatListLoading.set(null);
                }),
                take(1),
            )
            .subscribe();
    }

    loadChatsUnread() {
        if (this.unreadLoading._) return;

        const params = {
            filter: buildFilterStr({
                '~not_read_messages_count': 0,
            }),
            sort: '-dt_updated',
        };

        this.entityService
            .getEntityList$('chat.chat', { params, limit: 1000, saveSeparateItems: true })
            .pipe(
                tap((r) => {
                    this.unread.set(r.list.map((v) => v.id));
                    return;
                }),
                catchError((e) => {
                    return of(null);
                }),
                finalize(() => {
                    this.unreadLoading.set(null);
                }),
                take(1),
            )
            .subscribe();
    }

    modifyChatItem(chatId, diff = 0, message: EntData = null, addChat = false) {
        const chatItem = this.entityService.getEntity('chat.chat', chatId);
        if (!chatItem) return;

        const newItem = {
            ...chatItem,
            $snapshot: {
                ...chatItem.$snapshot,
            },
        };

        if (diff) {
            const notReadCount = chatItem.$snapshot.not_read_messages_count + diff;
            newItem.$snapshot.not_read_messages_count = notReadCount > 0 ? notReadCount : 0;
        }

        const needUpdateMessage =
            message &&
            ((newItem.$snapshot.last_message && message.id === newItem.$snapshot.last_message.id) ||
                new Date(message.$snapshot.dt_created).valueOf() >
                    new Date(newItem.$snapshot.dt_updated).valueOf());

        if (needUpdateMessage) {
            newItem.$snapshot.dt_updated = message.$snapshot.dt_created;
            newItem.$snapshot.last_message = {
                text: message.$snapshot.text,
                dt_created: message.$snapshot.dt_created,
                dt_updated: message.$snapshot.dt_updated,
                id: message.id,
                files: message.$snapshot.file__list,
                author: [
                    {
                        first_name: message.$snapshot.author_info.first_name,
                        last_name: message.$snapshot.author_info.last_name,
                    },
                ],
            };
        }

        this.entityService.setEntity(newItem);

        if (needUpdateMessage) {
            this.addChatToChatList(chatId);
        }

        if (diff || addChat) {
            const notReadCount = newItem.$snapshot.not_read_messages_count;
            if (notReadCount > 0) {
                this.unread.set(Array.from(new Set([...this.unread._, chatId])));
            } else {
                this.unread.set(this.unread._.filter((v) => v !== chatId));
            }
        }

        return newItem;
    }

    addMessageToChatList(chatId, message) {
        if (this.chatMessages?.chatId !== chatId) return;

        const messages = [...this.chatMessages.messages._];

        const index = messages.findIndex((v) => v.id === message.id);
        const listItem = this.generateMessageListItemFromEntity(message);

        if (index === -1) {
            messages.push(listItem);
        } else {
            messages[index] = listItem;
        }

        messages.sort((a, b) => b.dt_created - a.dt_created);
        this.chatMessages.messages.set(messages);
    }

    markOlderMessagesAsRead(chatId, messageId, readBy, userId) {
        if (this.chatMessages?.chatId !== chatId) return;
        const index = this.chatMessages.messages._.findIndex((v) => v.id === messageId);
        if (index === -1) return;
        for (let i = 0; i <= index; i++) {
            const entity = this.entityService.getEntity(
                'chat.chat_message',
                this.chatMessages.messages._[i].id,
            );
            if (!entity || entity.$snapshot.author?.id !== userId) continue;

            const isRead =
                entity.$snapshot.read_by.findIndex((v) => v.id !== entity.$snapshot.author?.id) >
                -1;

            if (isRead) continue;
            this.markExistedMessageAsRead(messageId, chatId, readBy);
        }
    }

    generateMessageListItemFromEntity(message: EntData) {
        const item = {
            id: message.id,
            dt_created: new Date(message.$snapshot.dt_created).valueOf(),
        };
        return item;
    }

    sortChatsList(a, b) {
        return (
            new Date(b.$snapshot.dt_updated).valueOf() - new Date(a.$snapshot.dt_updated).valueOf()
        );
    }

    protected nextMessages: TemplateObservable<{
        chatId: string;
        messageId?: string;
        type: 'newer' | 'older' | 'full' | 'message';
    }> = new TemplateObservable(null);

    getChatMessages$ = this.nextMessages.$.pipe(
        switchMap((settings): Observable<any> => {
            if (!settings) return of(null);
            if (settings.type === 'message') {
                return this.loadChatMessagesByMessageId$(settings.chatId, settings.messageId);
            }
            return this.loadChatMessages$(settings.chatId, settings.type);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    getNextMessages(settings: {
        chatId: string;
        messageId?: string;
        type: 'newer' | 'older' | 'full' | 'message';
    }) {
        if (
            settings.chatId === this.chatMessages.chatId &&
            settings.type !== 'full' &&
            settings.type !== 'message' &&
            (this.chatMessages.loading._.indexOf(settings.type) > -1 ||
                this.chatMessages.loading._.indexOf('full') > -1 ||
                this.chatMessages.loading._.indexOf('message') > -1)
        ) {
            return;
        }

        this.nextMessages.set(settings);
    }

    getPinnedMessages(chatId: string) {
        this.entityService
            .getEntityList$('chat.chat_message', {
                params: { filter: buildFilterStr({ is_pinned: true, chat: chatId }) },
                limit: 1000,
                saveSeparateItems: true,
            })
            .pipe(
                tap((r) => {
                    this.chatMessages.pinnedMessages.set(
                        r.list.map((v) => this.generateMessageListItemFromEntity(v)),
                    );
                }),
                take(1),
            )
            .subscribe();
    }

    loadChatMessages$(chatId: string, type: 'newer' | 'older' | 'full' = 'full') {
        if (!this.chatMessages) return;
        this.chatMessages.loading.set(this.chatMessages.loading._.concat([type]));

        const messageFilters: any = { chat: chatId };
        if (this.chatMessages.messages._.length > 0) {
            if (type === 'older') {
                const entity = this.entityService.getEntity(
                    'chat.chat_message',
                    this.chatMessages.messages._[this.chatMessages.messages._.length - 1].id,
                );
                messageFilters.dt_created__lt = entity.$snapshot.dt_created;
            } else if (type === 'newer') {
                const entity = this.entityService.getEntity(
                    'chat.chat_message',
                    this.chatMessages.messages._[0].id,
                );
                messageFilters.dt_created__gt = entity.$snapshot.dt_created;
            }
        }

        const params = {
            filter: buildFilterStr(messageFilters),
        };

        return this.entityService
            .getEntityList$('chat.chat_message', { params, saveSeparateItems: true })
            .pipe(
                tap((r) => {
                    const newMessages = r.list.map((v) =>
                        this.generateMessageListItemFromEntity(v),
                    );

                    const newList =
                        type === 'older'
                            ? this.chatMessages.messages._.concat(newMessages)
                            : type === 'newer'
                            ? newMessages.concat(this.chatMessages.messages._)
                            : newMessages;
                    if (type === 'full') {
                        this.notSentMessages = this.notSentMessages.filter(
                            (notSentMessage) =>
                                newList.findIndex((existed) => existed.id === notSentMessage.id) ===
                                -1,
                        );
                        this.notSentMessages.forEach((notSentMessage) => {
                            newList.push(notSentMessage);
                        });
                        newList.sort((a, b) => b.dt_created - a.dt_created);
                    }

                    this.chatMessages.messages.set(newList);

                    if (type === 'full' && newList.length > 0) {
                        this.modifyChatItem(chatId, 0, r.list[0]);
                    }

                    if (type === 'full') {
                        this.isEndOfCurrentChat.set(true);
                        this.isStartOfCurrentChat.set(
                            r.$meta.pagination.count <= r.$meta.pagination.limit,
                        );
                    }
                    if (type === 'newer') {
                        this.isEndOfCurrentChat.set(
                            r.$meta.pagination?.count <= r.$meta.pagination.limit,
                        );
                    }
                    if (type === 'older') {
                        this.isStartOfCurrentChat.set(
                            r.$meta.pagination?.count <= r.$meta.pagination.limit,
                        );
                    }
                    this.chatMessages.loading.set(
                        this.chatMessages.loading._.filter((v) => v !== type),
                    );
                }),
                catchError((e) => {
                    console.log(e);

                    this.chatMessages.loading.set(
                        this.chatMessages.loading._.filter((v) => v !== type),
                    );
                    this.nextMessages.set(null);
                    return of(null);
                }),
                take(1),
            );
    }

    loadChatMessagesByMessageId$(chatId: string, messageId: string, preCount = 21, postCount = 21) {
        this.chatMessages.loading.set(this.chatMessages.loading._.concat(['message']));
        return this.srvService
            .commonGetRequest$(`chat/chat_message/${messageId}/target_message`, {
                pre_count: preCount,
                post_count: postCount,
            })
            .pipe(
                map((r) => {
                    this.isEndOfCurrentChat.set(r.post_messages.data.length < postCount);
                    this.isStartOfCurrentChat.set(r.pre_messages.data.length < preCount);
                    const post = r.post_messages.data
                        .splice(0, 20)
                        .map((message) => this.srvService.makeupEntity(message))
                        .reverse();
                    const pre = r.pre_messages.data
                        .splice(0, 20)
                        .map((message) => this.srvService.makeupEntity(message));
                    const target = r.target_message.data.map((message) =>
                        this.srvService.makeupEntity(message),
                    );
                    const all = post.concat(target).concat(pre);
                    all.forEach((message) => {
                        this.entityService.setEntity(message);
                    });
                    const newMessages = all.map((v) => this.generateMessageListItemFromEntity(v));

                    this.chatMessages.messages.set(newMessages);
                    this.chatMessages.loading.set(
                        this.chatMessages.loading._.filter((v) => v !== 'message'),
                    );
                    return all;
                }),
                catchError((e) => {
                    this.chatMessages.loading.set(
                        this.chatMessages.loading._.filter((v) => v !== 'message'),
                    );
                    this.nextMessages.set(null);

                    return of(null);
                }),
                take(1),
            );
    }

    markMessagesAsRead$(ids: string[], userId, chatId: string) {
        const requests: GroupRequestItem[] = ids.map((id) => {
            return {
                entityType: 'chat.chat_message.read',
                meta: [],
                params: null,
                requestType: 'item',
                body: {
                    message_id: id,
                },
                method: 'POST',
            };
        });
        const observable$ = this.srvService.groupRequest$(requests).pipe(
            tap(() => {
                ids.forEach((id) => {
                    this.markExistedMessageAsRead(id, chatId, userId);
                });
                this.modifyChatItem(chatId, -ids.length);
            }),
            take(1),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
        observable$.subscribe();
        return observable$;
    }

    markExistedMessageAsRead(messageId, chatId, userId) {
        const entity = this.entityService.getEntity('chat.chat_message', messageId);
        if (entity) {
            this.entityService.setEntity({
                ...entity,
                $snapshot: {
                    ...entity.$snapshot,
                    is_readed: true,
                    read_by: [
                        ...entity.$snapshot.read_by,
                        { id: userId, type: this.authService.userEntityType },
                    ],
                },
            });
        }
    }

    sendReaction(reactionId: string, messageId: string, chatId: string) {
        this.entityService
            .createEntity$(
                'chat.chat_message_reaction',
                {},
                {
                    chat_message: messageId,
                    emoji: reactionId,
                },
                ['chat_message'],
            )
            .pipe(
                switchMap(() =>
                    this.entityService.getEntity$('chat.chat_message', messageId, {
                        forceLoad: true,
                    }),
                ),
                catchError((e) => of(null)),
                take(1),
            )
            .subscribe();
    }

    removeReaction(reactionId: string, messageId: string) {
        this.entityService
            .deleteEntity$('chat.chat_message_reaction', reactionId)
            .pipe(
                switchMap(() =>
                    this.entityService.getEntity$('chat.chat_message', messageId, {
                        forceLoad: true,
                    }),
                ),
                catchError((e) => of(null)),
                take(1),
            )
            .subscribe();
    }

    multipleReactions(sendReactionIds = [], removeReactionIds = [], messageId: string) {
        const sendReactions: GroupRequestItem[] = sendReactionIds.map((reactionId) => {
            const relationshipsData = this.entityService.buildEntityRelationships(
                'chat.chat_message_reaction',
                {
                    chat_message: messageId,
                    emoji: reactionId,
                },
            );
            const data = this.srvService.generateEntityBody(
                'chat.chat_message_reaction',
                null,
                {},
                relationshipsData,
            );

            return {
                entityType: 'chat.chat_message_reaction',
                meta: [],
                params: null,
                requestType: 'item',
                body: {
                    ...data,
                },
                method: 'POST',
            };
        });

        const deleteReactions: GroupRequestItem[] = removeReactionIds.map((reactionId) => {
            return {
                entityType: 'chat.chat_message_reaction',
                entityId: reactionId,
                meta: [],
                params: null,
                requestType: 'item',
                body: {},
                method: 'DELETE',
            };
        });

        const requests: GroupRequestItem[] = deleteReactions.concat(sendReactions);

        this.srvService
            .groupRequest$(requests)
            .pipe(
                switchMap(() =>
                    this.entityService.getEntity$('chat.chat_message', messageId, {
                        forceLoad: true,
                    }),
                ),
                catchError((e) => of(null)),
                take(1),
            )
            .subscribe();
    }

    wrapLinks(text) {
        const replacer = (match) => {
            return `<a href="${match}" target="_blank">${match}</a>`;
        };

        return text.replace(/&nbsp;/g, ' ').replace(this.urlRegex, replacer);
    }

    sendMessage$(
        chatId: string,
        text: string = '',
        files: string[] = [],
        replyTo: string = null,
        id: string = null,
    ) {
        text = text ? this.wrapLinks(text) : text;
        const attributes = { text };
        const relationships = {
            files: files || [],
            reply_to: replyTo,
            chat: chatId,
        };
        const desiredId = id || uuidv4();
        const prebuiltMessage = this.prebuildMessage(attributes, relationships, desiredId, !id);

        this.entityService.setEntity(prebuiltMessage);
        if (this.isEndOfCurrentChat._) {
            this.addMessageToChatList(chatId, prebuiltMessage);
        } else {
            this.notSentMessages.push(this.generateMessageListItemFromEntity(prebuiltMessage));
        }

        let observable$ = id
            ? this.entityService.updateEntity$('chat.chat_message', id, attributes, relationships, [
                  'files',
              ])
            : this.entityService.createEntity$(
                  'chat.chat_message',
                  attributes,
                  relationships,
                  ['files'],
                  desiredId,
              );

        observable$ = observable$.pipe(
            tap((response) => {
                this.entityService.setEntity(response);

                if (id) return;
                this.modifyChatItem(chatId, 0, response);
                if (this.isEndOfCurrentChat._) this.addMessageToChatList(chatId, response);

                if (
                    this.chatList._.length > 0 &&
                    this.chatList._.findIndex((v) => v === chatId) === -1
                ) {
                    this.loadChats('newer');
                }
            }),
            take(1),
            catchError((e) => {
                if (!e?.error?.errors) return of(null);

                if (e?.error?.errors?.find((v) => v.code === 'CHAT_IS_CLOSED')) {
                    const chatEntity = this.entityService.getEntity('chat.chat', chatId);
                    this.entityService.setEntity({
                        ...chatEntity,
                        $snapshot: { ...chatEntity.$snapshot, is_closed: true },
                    });
                }
                return of(null);
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
        observable$.subscribe();
        return observable$;
    }

    prebuildMessage(attributes, relationships, id, isNew = false) {
        const replyToEntity = relationships.reply_to
            ? this.entityService.getEntity('chat.chat_message', relationships.reply_to)
            : null;

        const existedMessage = this.entityService.getEntity('chat.chat_message', id);
        const existedFiles = existedMessage ? existedMessage.$snapshot.file__list : [];

        return {
            id,
            type: 'chat.chat_message',
            $snapshot: {
                ...attributes,
                ...relationships,
                id,
                type: 'chat.chat_message',
                dt_created: moment().format(API_DATETIME_FORMAT),
                dt_updated: moment().format(API_DATETIME_FORMAT),
                is_edited: !isNew,
                author_info: null,
                $sending: true,
                file__list: relationships.files.map((fileId) => {
                    const fileIndex = existedFiles.findIndex((v) => v.id === fileId);
                    if (fileIndex > -1) {
                        return {
                            ...existedFiles[fileIndex],
                        };
                    }
                    const fileEnt = this.entityService.getEntity('uploadable.file_upload', fileId);
                    return {
                        extension: fileEnt.$snapshot.extension,
                        file: fileEnt.$snapshot.file,
                        id: fileId,
                        name: fileEnt.$snapshot.name,
                        size: fileEnt.$snapshot.size,
                    };
                }),
                reaction__list: [],
                read_by: [],
                reply_to__detail: replyToEntity
                    ? {
                          id: relationships.reply_to,
                          type: 'chat.chat_message',
                          author: replyToEntity.$snapshot.author_info,
                          text: replyToEntity.$snapshot.text,
                          file__list: replyToEntity.$snapshot.file__list,
                      }
                    : null,
            },
        };
    }

    createChat$(
        entityType: string,
        entityId: string,
        text = null,
        files = [],
        additionalCreateParams = null,
    ) {
        return this.srvService
            .sendFormRequest$(
                `${entityType.split('.').join('/')}/${entityId}/create_chat`,
                additionalCreateParams ? additionalCreateParams : {},
                false,
            )
            .pipe(
                switchMap((data) => {
                    return text || files.length > 0
                        ? zip([of(data.id), this.sendMessage$(data.id, text, files)])
                        : of([data.id]);
                }),
                shareReplay(SHARE_REPLAY_SETTINGS),
            );
    }

    removeMessage$(chatId, messageId) {
        const observable$ = this.entityService.deleteEntity$('chat.chat_message', messageId).pipe(
            switchMap(() => this.updateChatListItemAfterMessageRemove$(messageId, chatId)),
            take(1),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        observable$.subscribe();
        return observable$;
    }

    updateChatListItemAfterMessageRemove$(messageId, chatId) {
        if (this.chatMessages?.chatId === chatId) {
            this.chatMessages.messages.set(
                this.chatMessages.messages._.filter((v) => v.id !== messageId),
            );
            this.chatMessages.messages._.forEach((message) => {
                const messageEnt = this.entityService.getEntity('chat.chat_message', message.id);
                if (messageEnt.$snapshot.reply_to?.id === messageId) {
                    this.entityService.setEntity({
                        ...messageEnt,
                        $snapshot: {
                            ...messageEnt.$snapshot,
                            reply_to: null,
                            reply_to__detail: null,
                        },
                    });
                }
            });
        }
        return this.entityService.getEntity$('chat.chat', chatId, { forceLoad: true });
    }

    clearChats() {
        this.unread.set([]);
        this.chatList.set([]);
        this.chatMessages = null;
        this.unreadLoading.set(false);
        this.nextMessages.set(null);
        this.isEndOfCurrentChat.set(false);
        this.isStartOfCurrentChat.set(false);
        this.chatListLoading.set(null);
    }

    getEmojiUrlByContent(name: string) {
        return this.projectEmojis.indexOf(name) > -1
            ? `/assets/emojis/${name}`
            : `/assets/bazis-assets/emojis/${name}`;
    }

    getReactionUrlByContent(name) {
        return this.projectReactions.indexOf(name) > -1
            ? `/assets/reactions/${name}`
            : `/assets/bazis-assets/reactions/${name}`;
    }

    getChatByContext$(contextModel, contextId) {
        return this.entityService
            .getEntityList$('chat.chat', {
                params: {
                    filter: buildFilterStr({ context_id: contextId, context_model: contextModel }),
                },
                limit: 1,
            })
            .pipe(map((v) => v.list[0] || null));
    }

    getChatContextUrl(contextModel: string, contextId: string, role = null) {
        return null;
    }

    openOrganizationData(organizationId) {
        return null;
    }

    getChatPhotos$(chatId, offset, limit = 50): Observable<EntList> {
        return this.entityService.getEntityList$('uploadable.file_upload', {
            params: {
                filter: buildFilterStr({
                    chat_messages__chat__id: chatId,
                    extension: FILE_EXTENSIONS.image.concat(
                        FILE_EXTENSIONS.image.map((v) => v.toUpperCase()),
                    ),
                }),
            },
            offset,
            limit,
        });
    }

    getChatDocs$(chatId, offset, limit = 100): Observable<EntList> {
        return this.entityService.getEntityList$('uploadable.file_upload', {
            params: {
                filter: buildFilterStr(
                    {
                        chat_messages__chat__id: chatId,
                        '~extension': FILE_EXTENSIONS.image.concat(
                            FILE_EXTENSIONS.image.map((v) => v.toUpperCase()),
                        ),
                    },
                    { individualSettings: { '~extension': { groupConnector: '&' } } },
                ),
            },
            offset,
            limit,
        });
    }

    getChatLinks$(chatId, offset, limit = 100): Observable<EntList> {
        return this.entityService.getEntityList$('chat.chat_message', {
            params: {
                filter: buildFilterStr({ text__$search: ['http://', 'https://'], chat: chatId }),
            },
            offset,
            limit,
        });
    }

    getChatUsers$(chatId): Observable<any> {
        return this.srvService.commonGetRequest$(`chat/chat/${chatId}/users`);
    }

    addUsers$(chatId, userIds, target = null): Observable<any> {
        const requests: GroupRequestItem[] = userIds.map((id) => {
            return {
                entityType: `chat.chat.${chatId}.add_user`,
                meta: [],
                params: null,
                requestType: 'item',
                body: {
                    user_id: id,
                    target,
                },
                method: 'POST',
            };
        });
        return this.srvService.groupRequest$(requests).pipe(
            catchError((e) => {
                const message = this.srvService.generateErrorMessage(e);
                this.toastService.create({
                    titleKey: 'toast.apiError.title',
                    messageKey: 'toast.apiError.message',
                    messageParams: { message },
                    type: 'error',
                });
                return of(e);
            }),
        );
    }

    pinMessage$(message, chatId, pinFlag): Observable<any> {
        return this.srvService
            .sendFormRequest$(`chat/chat/${chatId}/${pinFlag ? 'pin' : 'unpin'}_message`, {
                message_id: message.id,
            })
            .pipe(
                tap((v) => {
                    this.modifyMessageItemAfterMessagePin(message, chatId, pinFlag);
                }),
            );
    }

    modifyMessageItemAfterMessagePin(message, chatId, pinFlag) {
        if (this.chatMessages?.chatId !== chatId) return;

        if (pinFlag) {
            this.chatMessages.pinnedMessages.set(
                Array.from(new Set([...this.chatMessages.pinnedMessages._, message])).sort(
                    (a, b) => -a.dt_created + b.dt_created,
                ),
            );
        } else {
            this.chatMessages.pinnedMessages.set(
                this.chatMessages.pinnedMessages._.filter((v) => v.id !== message.id),
            );
        }

        const existedEntity = this.entityService.getEntity('chat.chat_message', message.id);
        if (!existedEntity) return;

        this.entityService.setEntity({
            ...existedEntity,
            $snapshot: {
                ...existedEntity.$snapshot,
                is_pinned: pinFlag,
            },
        });
    }

    deleteUser$(chatId, userId): Observable<any> {
        return this.entityService.deleteEntity$(`chat.chat.${chatId}.delete_user`, userId);
    }

    removeChatFromChatList(chatId) {
        this.chatList.set(this.chatList._.filter((v) => v !== chatId));
        if (this.nextMessages._?.chatId === chatId) this.nextMessages.set(null);
    }

    addChatToChatList(chatId: string | string[]) {
        if (Array.isArray(chatId)) {
            this.chatList.set(Array.from(new Set([...this.chatList._, ...chatId])));
        } else {
            this.chatList.set(Array.from(new Set([...this.chatList._, chatId])));
        }
    }

    titleClick(action, chat) {
        if (!action) return;
    }
}
