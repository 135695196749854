<ng-container *ngIf="{ loaders: loaderService.loaders.$ | async } as data">
    <div
        *ngIf="data.loaders.length > 0"
        class="bazis-loader"
    >
        <div class="bazis-loader__content">
            <div
                *ngIf="loader === 'dots'"
                class="bazis-loader__dots"
            >
                <div
                    *ngFor="let item of [].constructor(amountDots)"
                    class="bazis-loader__dot"
                ></div>
            </div>
            <svg
                *ngIf="loader === 'spinner'"
                class="bazis-loader__spinner"
            >
                <circle
                    [attr.cx]="centerX"
                    [attr.cy]="centerY"
                    [attr.r]="radius"
                    fill="none"
                    stroke-width="1"
                ></circle>
            </svg>
            <ng-container *transloco="let t">
                <p *ngFor="let loader of data.loaders">
                    {{
                        loader.options.messageKey
                            ? t(loader.options.messageKey)
                            : loader.options.message
                    }}
                </p>
            </ng-container>
        </div>
    </div>
</ng-container>
