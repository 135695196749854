import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { BazisToastService, TOAST_GROUPS } from '@bazis/shared/services/toast.service';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SignStatusModule } from '@bazis/shared/components/web/sign-status/sign-status.module';
import { IconModule } from '@bazis/shared/components/web/icon/icon.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { TranslocoModule } from '@jsverse/transloco';
import { SafePipe } from '@bazis/shared/pipes/safe.pipe';

@Component({
    selector: 'bazis-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['toast.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        SignStatusModule,
        IconModule,
        ColorDirective,
        TranslocoModule,
        SafePipe,
    ],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnDestroy, OnInit {
    // TODO: подумать можно ли переделать в web-компонент

    groupNames = TOAST_GROUPS;

    currentGroups$ = this.toastService.toastGroups.$.pipe(
        map((groups) => {
            return this.groupNames.filter((group) => groups[group].length);
        }),
    );

    constructor(public toastService: BazisToastService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    dismiss(id, group) {
        this.toastService.dismiss(id, group);
    }
}
