import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TooltipModule } from '@bazis/shared/components/web/tooltip/tooltip.module';
import { ChipModule } from '@bazis/shared/components/web/chip/chip.module';
import { ColorDirective } from '@bazis/shared/directives/color.directive';
import { CommonModule } from '@angular/common';
import { IconModule } from '../web/icon/icon.module';

@Component({
    selector: 'bazis-status',
    templateUrl: './status.component.html',
    standalone: true,
    imports: [TooltipModule, ChipModule, ColorDirective, CommonModule, IconModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
    @Input() label: string;

    @Input() color: string;

    @Input() size: 'small' | '' = '';

    @Input() hasDecor: boolean = false;

    @Input() fill?: 'clear' | 'outline' | 'solid';

    @Input()
    isStaticTooltip: boolean = false;

    @Input()
    hasTooltip: boolean = true;

    @Input() iconStart: string = '';

    @Input() iconEnd: string = '';

    @HostBinding('class') get statusStyle() {
        return {
            'bazis-status_nodecor': !this.hasDecor,
            'bh-pointer': this.hasTooltip,
        };
    }

    constructor() {}
}
