<div
    class="bazis-tabs-wrapper"
    part="wrapper"
    *ngIf="tabList?.length"
>
    <ng-container *ngFor="let tab of tabList">
        <ng-container *ngIf="tab.underconstruction">
            <button
                *ngIf="!hideByZeroCount || tab.id === selectedTab || tab.titleParams?.count > 0"
                class="bazis-tab-btn bh-underconstruction"
                [class.hidden]="tab.hidden"
                [title]="underconstructionKey | transloco"
                [attr.disabled]="tab.disabled"
            >
                <ng-container
                    [ngTemplateOutlet]="tabContent"
                    [ngTemplateOutletContext]="{
                        tab: tab
                    }"
                ></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="!tab.underconstruction">
            <ng-container *ngIf="tab.routerLink">
                <!-- если табы ссылочные, то активность таба = активности ссылки если табы будут см -->
                <button
                    *ngIf="!hideByZeroCount || tab.isExactLink || tab.titleParams?.count > 0"
                    class="bazis-tab-btn"
                    [ngClass]="{
                        hidden: tab.hidden
                    }"
                    [attr.disabled]="tab.disabled"
                    [routerLink]="tab.routerLink"
                    routerLinkActive="bazis-tab-btn--selected"
                    [routerLinkActiveOptions]="{ exact: tab.isExactLink }"
                    (click)="selectTab(tab.id)"
                >
                    <ng-container
                        [ngTemplateOutlet]="tabContent"
                        [ngTemplateOutletContext]="{
                            tab: tab
                        }"
                    ></ng-container>
                </button>
            </ng-container>
            <ng-container *ngIf="!tab.routerLink">
                <!-- если таб это не ссылка, то активируем таб с текущим значением tabControl -->
                <button
                    *ngIf="!hideByZeroCount || tab.id === selectedTab || tab.titleParams?.count > 0"
                    class="bazis-tab-btn"
                    [ngClass]="{
                        'bazis-tab-btn--selected': tab.id === selectedTab,
                        hidden: tab.hidden
                    }"
                    [attr.disabled]="tab.disabled"
                    (click)="selectTab(tab.id)"
                >
                    <ng-container
                        [ngTemplateOutlet]="tabContent"
                        [ngTemplateOutletContext]="{
                            tab: tab
                        }"
                    ></ng-container>
                </button>
            </ng-container>
        </ng-container>
    </ng-container>
    <div
        [hidden]="!hasMore"
        class="bazis-tab_more"
    >
        <button
            *transloco="let t"
            class="bazis-tab-btn bazis-tab-btn_trigger"
            (click)="toggleMoreTab($event)"
        >
            <span>{{ t('title.more') }} ({{ moreInsideItemsCounter }})</span>
            <!-- <bazis-badge
                *ngIf="tab.hasBadge"
                [size]="tab.badgeSize"
                part="badge"
            ></bazis-badge> -->
            <bazis-icon
                [name]="showMore ? upIcon : downIcon"
                size="s"
            ></bazis-icon>
        </button>
        <div
            [hidden]="!showMore"
            class="bazis-tab__dropdown"
            part="dropdown"
        >
            <ng-container *ngFor="let tab of tabList">
                <ng-container *ngIf="tab.underconstruction">
                    <button
                        *ngIf="
                            !hideByZeroCount || tab.id === selectedTab || tab.titleParams?.count > 0
                        "
                        class="bazis-tab-btn bh-underconstruction"
                        [class.hidden]="!tab.hidden"
                        [title]="underconstructionKey | transloco"
                        [attr.disabled]="tab.disabled"
                    >
                        <ng-container
                            [ngTemplateOutlet]="tabContent"
                            [ngTemplateOutletContext]="{
                                tab: tab
                            }"
                        ></ng-container>
                    </button>
                </ng-container>
                <ng-container *ngIf="!tab.underconstruction">
                    <ng-container *ngIf="tab.routerLink">
                        <!-- если табы ссылочные, то активность таба = активности ссылки если табы будут см -->
                        <button
                            *ngIf="
                                !hideByZeroCount || tab.isExactLink || tab.titleParams?.count > 0
                            "
                            class="bazis-tab-btn"
                            [ngClass]="{
                                'bazis-tab-btn--selected': tab.id === selectedTab,
                                hidden: !tab.hidden
                            }"
                            [attr.disabled]="tab.disabled"
                            [routerLink]="tab.routerLink"
                            routerLinkActive="active-link"
                            [routerLinkActiveOptions]="{ exact: tab.isExactLink }"
                            (click)="selectTab(tab.id)"
                        >
                            <ng-container
                                [ngTemplateOutlet]="tabContent"
                                [ngTemplateOutletContext]="{
                                    tab: tab
                                }"
                            ></ng-container>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!tab.routerLink">
                        <!-- если таб это не ссылка, то активируем таб с текущим значением tabControl -->
                        <button
                            *ngIf="
                                !hideByZeroCount ||
                                tab.id === selectedTab ||
                                tab.titleParams?.count > 0
                            "
                            class="bazis-tab-btn"
                            [ngClass]="{
                                'bazis-tab-btn--selected': tab.id === selectedTab,
                                hidden: !tab.hidden
                            }"
                            [attr.disabled]="tab.disabled"
                            (click)="selectTab(tab.id)"
                        >
                            <ng-container
                                [ngTemplateOutlet]="tabContent"
                                [ngTemplateOutletContext]="{
                                    tab: tab
                                }"
                            ></ng-container>
                        </button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template
    #tabContent
    let-tab="tab"
>
    <ng-container *ngIf="!tabTemplate; else tplTabTemplate">
        <bazis-icon
            *ngIf="tab.icon"
            [icon]="tab.icon"
        ></bazis-icon>
        <span *ngIf="tab.title">
            {{ tab.title }}
        </span>
        <span
            *ngIf="tab.titleKey"
            [innerHTML]="tab.titleKey | transloco : tab.titleParams"
        >
        </span>
        <bazis-badge
            *ngIf="tab.hasBadge"
            [size]="tab.badgeSize"
            part="badge"
        >
        </bazis-badge>
    </ng-container>
    <ng-template #tplTabTemplate>
        <span *ngIf="tab.entityType; else tplNoEntityType">
            <ng-container
                [ngTemplateOutlet]="tabTemplate"
                [ngTemplateOutletContext]="{
                    id: tab.id,
                    entityType: tab.entityType,
                    titleParams: tab.titleParams,
                    titleKey: tab.titleKey
                }"
            ></ng-container>
        </span>
        <ng-template #tplNoEntityType>
            <span>
                <ng-container
                    [ngTemplateOutlet]="tabTemplate"
                    [ngTemplateOutletContext]="{
                        id: tab.id,
                        titleParams: tab.titleParams,
                        titleKey: tab.titleKey
                    }"
                ></ng-container>
            </span>
        </ng-template>
    </ng-template>
</ng-template>
