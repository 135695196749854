import { AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { EntData, EntDescription, SchemaType } from '@bazis/shared/models/srv.types';

export class EntityFormGroup extends FormGroup {
    $config?: any;

    $savingStatus?: FormSavingStatus;
}
export class EntityFormArray extends FormArray {
    $config?;
}
export class EntityFormControl extends FormControl {
    $config?;

    $savingStatus?: FormSavingStatus;
}
export type EntityAbstractControl = EntityFormGroup | EntityFormControl | EntityFormArray;

export type FormGroupSettings = {
    id: string;
    fields?: string[];
    validatorFns?: ValidatorFn[];
    asyncValidatorFns?: ValidatorFn[];
    groups?: FormGroupSettings[];
};
export type FormValidatorSettings = {
    type: 'email' | 'number' | 'min' | 'max' | 'minLength' | 'maxLength' | 'pattern' | 'custom';
    value?: any;
    validator?: any;
};

export type FormFieldConfig = FormSettings & {
    fieldType?: string;
    validators?: FormValidatorSettings[];
    required?: TemplateObservable<boolean>;
    forceRequired?: boolean; // use forceRequired - when front wants ignore schema value
    componentValidatorFns?: ValidatorFn[];
    type?: string;
    nullable?: boolean;
    hidden?: boolean;
    format?: string;
    maxLength?: number;
    default?: any; // default value from api
    initialValue?: any; // create instance with this property value
    titleKey?: string;
    tooltipKey?: string;
    noteKey?: string;
    readonly?: TemplateObservable<boolean>;
    valueToSendWhenFieldIsInvalid?: any;
    options?: any[];
    waitCreation?: boolean; // do not update parent while all child ids will be known (child elements created)
    anySettings?: any; // для любых настроект, которые могут быть необходимы компоненту
    keepArrayInSingleControl?: boolean; // чтобы массив объектов задать единым control, содержащим массив,
    isInnerEntity?: boolean; // inner entity will be part of the parent form (send/receive only using include)
    changedTimestamp?: number;
};

export type FormSettings = {
    id?: string; // for single entity
    ids?: string[]; // for array of entities
    desiredId?: string;
    entityType?: string;
    include?: string[];
    schemaInclude?: string[];
    updateAfterCreation?: boolean; // update form after creation
    // {'active': 'order/#id'} means that after transit that has in his name 'active'
    // we will make redirect to the page, changing #id on the id of the form
    transitRedirectTemplates?: { [index: string]: string };
    groups?: FormGroupSettings[];
    fields?: string[];
    reflectIdInUrl?: boolean;
    skipAutoSave?: boolean;
    fieldConfig?: {
        [index: string]: FormFieldConfig;
    };
    validationSchema?: { [index: string]: SchemaType };
    parent?: ParentEntity;
    canUseParentSchema?: boolean;
    validatorFns?: ValidatorFn[];
    asyncValidatorFns?: AsyncValidatorFn[];
    clearFormAfterTransitions?: string[];
    emitTransitDoneAfterEmptyTransitResult?: boolean; // later might become default behaviour
    // return true если форма была изменена с вызовом setValue...
    // return false, если форма не менялась и можно сохранять
    beforeUpdate?: (prevFormValue: any, currentFormValue: any, form: EntityFormGroup) => boolean;
};

export type FormSavingStatus = {
    status: 'process' | 'success' | 'error';
    time: number;
};

export type ParentEntity = {
    id: string;
    entityType: string;
    fieldConnector?: string;
};

export type GroupedOptionsSettings = {
    entityType?: string;
    parentType: string;
    connectionField: string;
};
export type GroupedOption = {
    groupDescription?: EntDescription;
    groupOptions: EntData[];
    groupEntity: EntData;
};
